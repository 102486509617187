import React, {useCallback, useState, useEffect} from 'react';
import {
  Box,
  Typography,
  Grid,
  Snackbar,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import Dropzone from 'react-dropzone';
import {styled} from '../../theme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import {API_HOST, API_ROOT} from '../../config';
import Cookies from 'js-cookie';
import {
  PayloadComparisonColumn,
  PayloadComparisonGrid,
} from '../Comparison/styled';
import {constants, Environment} from '../../constants';

const environment =
  constants.ENVIRONMENT === Environment.Production
    ? 'banks.talefin.com'
    : constants.ENVIRONMENT === Environment.UAT
    ? 'banks-uat.talefin.com'
    : 'banks-staging.talefin.com';

const DropzoneBox = styled.div`
  border: 4px dashed ${({theme}) => theme.palette.primary.main};
  border-radius: 10px;
  padding: 1em;
  &:hover,
  &:focus {
    background: #ededed;
  }
`;

const getCsrf = (): string => {
  return Cookies.get('csrftoken') || '';
};

interface FileDetails {
  name: string;
  size: number;
  content: string | ArrayBuffer | null;
  type: string;
  isVerified?: boolean;
}

const SignatureVerificationFileDropInner: React.FC = () => {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setError('');
  };

  const showError = (errorMessage: string) => {
    setError(errorMessage);
    setOpen(true);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const fileDetailsPromises = acceptedFiles.map(
        (file: File) =>
          new Promise<FileDetails>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
              resolve({
                name: file.name,
                size: file.size,
                content: reader.result,
                type: file.type.split('/')[1],
              });
            };

            reader.onerror = () => {
              reject(new Error('File reading failed'));
            };

            if (file.type === 'application/json' || file.type === 'text/html') {
              reader.readAsText(file);
            } else if (file.type === 'application/pdf') {
              reader.readAsArrayBuffer(file);
            } else {
              reject(new Error('Unsupported file type'));
            }
          }),
      );

      Promise.all(fileDetailsPromises)
        .then((fileDetails) => setFiles(fileDetails))
        .catch((err) => showError(err.message));
    },
    [showError],
  );

  const encodeFileContent = (file: FileDetails) => {
    try {
      if (file.type === 'json') {
        return JSON.parse(file.content as string);
      } else if (file.type === 'html') {
        return btoa(unescape(encodeURIComponent(file.content as string)));
      } else if (file.type === 'pdf') {
        const binary = new Uint8Array(file.content as ArrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        );
        return btoa(binary);
      } else {
        showError('Unsupported file type');
        return null;
      }
    } catch (e) {
      showError(`Failed to encode file content: ${e.message}`);
      return null;
    }
  };

  const verifyFileSignature = async (file: FileDetails, token: string) => {
    const encodedContent = encodeFileContent(file);
    if (!encodedContent) return {...file, isVerified: false};

    try {
      const response = await axios.post(
        `${API_HOST}/${API_ROOT}/verify-document-signature/`,
        {
          content: encodedContent,
          type: file.type,
          token,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCsrf(),
          },
        },
      );
      return {...file, isVerified: response.data.verified};
    } catch (error) {
      console.error('Error verifying signature:', error);
      showError(
        `Error verifying signature: ${
          error.response?.data?.message || error.message
        }`,
      );
      return {...file, isVerified: false};
    }
  };

  const handleSubmit = async () => {
    if (!window.grecaptcha) {
      showError('ReCaptcha is not ready, please try again later.');
      return;
    }

    try {
      setIsVerifying(true);
      const recaptchaSiteKey =
        constants.ENVIRONMENT === Environment.Production ||
        constants.ENVIRONMENT === Environment.UAT
          ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PRODUCTION
          : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_STAGING;
      //   const recaptcha_site_key =
      //     constants.ENVIRONMENT === Environment.Production ||
      //     constants.ENVIRONMENT === Environment.UAT
      //       ? '6LcD-2omAAAAAPWp3fI2cQlwZ0WNU1slWM7EYlrr'
      //       : '6LeeKG0mAAAAAC5P1X96L7CT9nujcDBdFsNzpfYv';
      console.log(
        `${constants.ENVIRONMENT} key is ${recaptchaSiteKey} in verification inner`,
      );

      const token = await window.grecaptcha.execute(
        '6LcD-2omAAAAAPWp3fI2cQlwZ0WNU1slWM7EYlrr',
        {
          action: 'signature_verification',
        },
      );
      //   const token = await window.grecaptcha.execute(
      //     '6LcddE8mAAAAADdD05bWqssEff39MIPnkEstLjOD',
      //     {action: 'signature_verification'},
      //   ); // for local development

      const verificationPromises = files.map((file) =>
        verifyFileSignature(file, token),
      );

      const updatedFiles = await Promise.all(verificationPromises);
      setFiles(updatedFiles);
    } catch (error) {
      if (process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY === '') {
        console.error(
          'GOOGLE_RECAPTCHA_SITE_KEY env is not correctly provided',
        );
      } else {
        console.error('Error processing files:', error);
        showError(`Error processing files: ${error.message}`);
      }
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <PayloadComparisonGrid>
      <PayloadComparisonColumn>
        <Typography variant="h5" align="center" gutterBottom>
          Instructions
        </Typography>
        <Box padding={2}>
          <Typography paragraph>
            Welcome to the Talefin Document Verification Service. Here, you can
            verify any Talefin-issued .json, .html, or .pdf files to ensure
            their authenticity and integrity.
          </Typography>
          <Typography paragraph>
            <strong>How to use:</strong>
          </Typography>
          <Typography component="ol">
            <li>
              Drag and drop your file into the area below, or click to select a
              file.
            </li>
            <li>Click "Verify" to check whether the signature is valid.</li>
            <li>The verification results will be displayed on this page.</li>
          </Typography>
          <Typography paragraph>
            <strong>Supported file types:</strong> JSON (.json), HTML (.html),
            PDF (.pdf)
          </Typography>
        </Box>
      </PayloadComparisonColumn>
      <PayloadComparisonColumn>
        <Box padding={2}>
          <Typography variant="h5" align="center" gutterBottom>
            File Drop Zone
          </Typography>
          <Dropzone onDrop={onDrop} maxFiles={10} accept=".json, .html, .pdf">
            {({getRootProps, getInputProps}) => (
              <DropzoneBox {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography>
                  Drag & drop your files here, or click to select files
                </Typography>
              </DropzoneBox>
            )}
          </Dropzone>

          <Typography variant="body1" paddingTop={2}>
            Accepted Files:
          </Typography>
          {files.length > 0 ? (
            <Grid container spacing={2} paddingTop={2}>
              {files.map((file, index) => (
                <Grid item xs={12} key={index}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">
                      {file.name} - {(file.size / 1024).toFixed(2)} KB
                    </Typography>
                    {file.isVerified !== undefined && (
                      <Box display="flex" alignItems="center" marginLeft={2}>
                        {file.isVerified ? (
                          <>
                            <CheckCircleIcon
                              style={{color: 'green', marginRight: 4}}
                            />
                            <Typography variant="body1" color="textSecondary">
                              Verified
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon
                              style={{color: 'red', marginRight: 4}}
                            />
                            <Typography variant="body1" color="error">
                              Verification Failed
                            </Typography>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" color="textSecondary" paddingTop={1}>
              No files uploaded yet.
            </Typography>
          )}

          <Box marginTop={4} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isVerifying || files.length === 0}
            >
              {isVerifying ? 'Verifying...' : 'Verify'}
            </Button>
          </Box>

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <Alert onClose={handleClose} severity="error" variant="filled">
              {error}
            </Alert>
          </Snackbar>
        </Box>
      </PayloadComparisonColumn>
    </PayloadComparisonGrid>
  );
};

export default SignatureVerificationFileDropInner;
