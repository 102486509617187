import axios from 'axios';
import React, {
  useContext,
  useState,
  useMemo,
  ComponentProps,
  useEffect,
  useCallback,
  ChangeEvent,
  FormEvent,
} from 'react';
import {useInterval} from 'react-use';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import {HOST, API_HOST, API_ROOT} from '../../config';
import ResultsPlaceholder from '../Demo/ResultsPlaceholder';
import {
  PayloadComparisonGrid,
  PayloadComparisonColumn,
  ResultsList,
  DropzoneBox,
} from './styled';
import {DateTime} from 'luxon';
import {v4} from 'uuid';
import {styled, theme} from '../../theme';
import Dropzone from 'react-dropzone';
import {
  AnalysisStatus,
  Bank,
  ComparisonFile,
  SummaryStatus,
  Vendor,
} from './types';
import Result from './Result';
import {FormInput} from '../ContactForm/styled';
import {LoadingButton} from '@material-ui/lab';
import Cookies from 'js-cookie';

type Artifact = ComponentProps<typeof Result>;

export function ActiveComparison() {
  const [page, setPage] = useState<'welcome' | 'active'>('welcome');
  const [vendor, setVendor] = useState('');
  // this is the uuid of the currently selected application
  const [activeUuid, setActiveUuid] = useState('');
  const [origin, setOrigin] = useState('');
  const [adjustTime, setAdjustTime] = useState(false);
  // this is the selected bank
  const [bank, setBank] = useState('');
  const [inputValue, setInputValue] = useState('');
  // this is the list of all banks to populate the dropdown
  const [banks, setBanks] = useState<Bank[]>([]);
  // we need an array of uuids in the case of multiple files
  const [applications, setApplications] = useState<string[]>([]);
  const [results, setResults] = useState<Artifact[]>([]);
  // we use this to stop making api calls on a completed application\
  const [
    isCurrentApplicationComplete,
    setCurrentApplicationComplete,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({email: '', password: ''});
  // changed from just payload strings in an attempt to display an accepted file list
  // however the list still is not updating (maybe need to memoize)
  const [comparisonFiles, setComparisonFiles] = useState<ComparisonFile[]>([]);

  const getCsrf = () => {
    return Cookies.get('csrftoken') || '';
  };

  useEffect(() => {
    getBanks();
    async function getBanks() {
      const response = await axios.get<Bank[]>(
        `${API_HOST}/${API_ROOT}/banks`,
        {headers: {'X-CSRFToken': getCsrf()}},
      );
      setBanks(response.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((acceptedFile: File) => {
        const reader = new FileReader();

        try {
          reader.onload = () => {
            const payload = reader.result as string;
            const existingFiles = comparisonFiles;
            const comparisonFile = {
              payload,
              name: acceptedFile.name,
              size: acceptedFile.size,
            };
            existingFiles.push(comparisonFile);
            setComparisonFiles([...existingFiles]);
          };

          reader.readAsText(acceptedFile);
        } catch (err) {
          console.log(err.message);
        }
      });
    },
    [comparisonFiles, setComparisonFiles],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (!origin) {
      setLoading(false);
      console.log('Please choose a source before submitting.');
      return;
    }
    for (const comparisonFile of comparisonFiles) {
      try {
        const vendorSpecificId = `${DateTime.now().toFormat('X')}-${v4().slice(
          0,
          8,
        )}`;
        const bankId = banks.find((b) => b.name == bank)?.id;
        const response = await axios.post(
          `${API_HOST}/${API_ROOT}/${vendor}/${vendorSpecificId}/classify/append`,
          {
            accounts: JSON.parse(comparisonFile.payload),
            bank: bankId,
            analyse_now: true,
            detect_dishonours: true,
            days_to_retrieve: 365,
            days_to_display: 365,
            origin: origin,
            adjust_time_to_today: adjustTime,
          },
          {headers: {'X-CSRFToken': getCsrf()}},
        );

        if (response.status >= 300) {
          throw new Error(
            `Append request failed with status ${response.status}`,
          );
        }
        const data = response.data;
        const applicationId = data.application_id.toString();
        const existingApplications = applications;
        existingApplications.push(applicationId);
        setApplications([...existingApplications]);
      } catch (err) {
        console.log(err);
      }
    }
    // TODO only remove succesful submissions
    setComparisonFiles([]);
    setResults([]);
    setLoading(false);
    return;
  }, [
    comparisonFiles,
    applications,
    origin,
    setComparisonFiles,
    setResults,
    setLoading,
    // setUuid,
    setApplications,
    getCsrf,
  ]);

  const removeFile = useCallback(
    async (file: ComparisonFile) => {
      const index = comparisonFiles.indexOf(file);
      if (index > -1) {
        const existingFiles = comparisonFiles;
        existingFiles.splice(index, 1);
        setComparisonFiles([...existingFiles]);
      }
      return;
    },
    [comparisonFiles, setComparisonFiles],
  );

  const handleLogin = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const action = e.currentTarget.action;
      setLoading(true);
      try {
        const loginResponse = (
          await axios.post(
            action,
            {email: loginInfo.email, password: loginInfo.password},
            {headers: {'X-CSRFToken': getCsrf()}},
          )
        ).data;
        const key = loginResponse.key;
        const token = `Token ${key}`;
        const user = (
          await axios.get(`${API_HOST}/${API_ROOT}/users/me/detailed`, {
            headers: {'X-CSRFToken': getCsrf(), Authorization: token},
          })
        ).data;
        const vendorId = user.vendor.id;
        const vendors = (
          await axios.get<Vendor[]>(
            `${API_HOST}/${API_ROOT}/dashboard/vendors/labels/`,
          )
        ).data;
        const vendor = vendors.find(
          (vendor: Vendor) => vendor.vendor_id === vendorId,
        );
        const vendorRef = vendor?.ref;
        setVendor(vendorRef || '');
        setPage('active');
      } catch (err) {
        console.log(err.message);
      }
      setLoading(false);
      return;
    },
    [loginInfo, setLoading, setPage, setVendor],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      setLoginInfo((prev) => ({...prev, [target.name]: target.value}));
    },
    [setLoginInfo, setLoading],
  );

  const handleOriginChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      setOrigin(e.target.value);
    },
    [setOrigin],
  );

  const handleBankChange = (
    _: React.ChangeEvent<{}>,
    option: string | null,
  ) => {
    setBank(option || '');
  };

  const handleBankInputChange = (
    _: React.ChangeEvent<{}>,
    input: string | null,
  ) => {
    setInputValue(input || '');
  };

  const handleCheck = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAdjustTime(e.target.checked);
    },
    [setAdjustTime],
  );

  const handleSelect = useCallback(
    async (uuid: string) => {
      // if the application is complete, we don't need to update
      if (uuid === activeUuid && isCurrentApplicationComplete) {
        return;
      }
      setActiveUuid(uuid);
      setCurrentApplicationComplete(false);
      const files: Artifact[] = [];
      const response = await axios.get(
        `${API_HOST}/${API_ROOT}/applications/${uuid}/analyses`,
      );
      const analyses = response.data;
      for (const analysis of analyses) {
        const response = await axios.get(
          `${API_HOST}/${API_ROOT}/analyses/${analysis.id}/filestatus/`,
        );
        const status: AnalysisStatus = await response.data;
        if (status.completed) {
          const json: Artifact = {
            id: analysis.id,
            url: `${API_HOST}/${API_ROOT}/analyses/${analysis.id}/report`,
            fileType: 'json',
            documentType: 'analysis',
          };
          files.push(json);
        }
        if (status.html) {
          const html: Artifact = {
            id: analysis.id,
            url: `${API_HOST}/${API_ROOT}/analyses/${analysis.id}/report/html`,
            fileType: 'html',
            documentType: 'analysis',
          };
          files.push(html);
        }
        if (status.pdf) {
          const pdf: Artifact = {
            id: analysis.id,
            url: `${API_HOST}/${API_ROOT}/analyses/${analysis.id}/report/pdf`,
            fileType: 'pdf',
            documentType: 'analysis',
          };
          files.push(pdf);
        }
      }
      const summaryResponse = await axios.get(
        `${API_HOST}/${API_ROOT}/applications/${uuid}/summary/filestatus/`,
      );
      const status: SummaryStatus = summaryResponse.data;
      if (status.analysed) {
        const summaryJson: Artifact = {
          id: uuid,
          url: `${API_HOST}/${API_ROOT}/applications/${uuid}/summary`,
          fileType: 'json',
          documentType: 'summary',
        };
        files.push(summaryJson);
      }
      if (status.html) {
        const summaryHtml: Artifact = {
          id: uuid,
          url: `${API_HOST}/${API_ROOT}/applications/${uuid}/summary/html`,
          fileType: 'html',
          documentType: 'summary',
        };
        files.push(summaryHtml);
      }
      if (status.pdf) {
        const summaryPdf: Artifact = {
          id: uuid,
          url: `${API_HOST}/${API_ROOT}/applications/${uuid}/summary/pdf`,
          fileType: 'pdf',
          documentType: 'summary',
        };
        files.push(summaryPdf);
      }
      // bit of a messy solution - since we don't listen for webhooks
      // this will check if all files are complete
      if (files.length === analyses.length * 3 + 3) {
        setCurrentApplicationComplete(true);
      }
      setResults([...files]);
    },
    [
      activeUuid,
      isCurrentApplicationComplete,
      setActiveUuid,
      setResults,
      setCurrentApplicationComplete,
    ],
  );

  useInterval(async () => {
    if (!activeUuid) {
      return;
    }
    // update the application file list every 5 seconds
    await handleSelect(activeUuid);
  }, 5000);

  // TODO add actual login functionality
  if (page == 'welcome') {
    return (
      <>
        <Typography variant="h5"> Talefin Payload Comparison Page </Typography>
        <Box paddingTop={2} paddingBottom={8}>
          <form
            action={`${API_HOST}/${API_ROOT}/rest-auth/login/`}
            onSubmit={handleLogin}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormInput
                  type="email"
                  name="email"
                  label="Email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <FormInput
                  type="password"
                  name="password"
                  label="Password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <LoadingButton type="submit" pending={loading}>
                  Login
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </>
    );
  }

  return (
    <PayloadComparisonGrid>
      <PayloadComparisonColumn>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Payload Selector
        </Typography>
        <Box padding={2}>
          <Typography variant="body1">Select a source:</Typography>
          <Select value={origin} onChange={handleOriginChange}>
            <MenuItem value={'creditsense-detailed'}>
              Credit Sense (Detailed Report)
            </MenuItem>
            <MenuItem value={'creditsense'}>Credit Sense (Data Only)</MenuItem>
            <MenuItem value={'illion'}>Illion</MenuItem>
            <MenuItem value={'flinks'}>Flinks</MenuItem>
            <MenuItem value={'plaid'}>Plaid</MenuItem>
          </Select>
        </Box>
        <Box padding={2}>
          <Typography variant="body1">Select a bank:</Typography>
          <Autocomplete
            freeSolo
            value={bank}
            inputValue={inputValue}
            options={banks.map((props) => {
              return props.name;
            })}
            onChange={handleBankChange}
            onInputChange={handleBankInputChange}
            renderInput={(params) => <TextField {...params} label="Bank" />}
          />
        </Box>
        <Box padding={2}>
          <FormGroup>
            <FormControlLabel
              style={{pointerEvents: 'none'}}
              control={
                <Checkbox
                  style={{pointerEvents: 'auto'}}
                  color="primary"
                  name="adjustTime"
                  onChange={handleCheck}
                />
              }
              label="Adjust transaction dates to today"
              sx={{border: 0}}
            />
          </FormGroup>
        </Box>
        <Dropzone onDrop={onDrop} maxFiles={10}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <DropzoneBox {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop your selected files here</p>
              ) : (
                <p>Drag & drop some files here, or click to select files</p>
              )}
            </DropzoneBox>
          )}
        </Dropzone>
        <Typography variant="body1">Accepted Files:</Typography>
        <Grid item maxWidth={'100%'}>
          {!comparisonFiles.length && (
            <Grid item width="100%">
              <Typography>Select a file and it will appear here</Typography>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item container padding={1} spacing={1} direction="column">
              {comparisonFiles.map((props) => {
                return (
                  <Grid item container key={props.name} direction="row">
                    <Grid item xs={9} paddingRight={1}>
                      <Typography noWrap variant="body2">
                        {props.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2">
                        {Math.round(props.size / 1024)} kb
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title="Remove">
                        <RemoveIcon
                          sx={{
                            '&:hover': {backgroundColor: '#ededed'},
                            borderRadius: '10px',
                          }}
                          onClick={() => removeFile(props)}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {comparisonFiles.length > 0 && (
            <LoadingButton onClick={handleSubmit} pending={loading}>
              Submit
            </LoadingButton>
          )}
        </Grid>
        <Typography variant="body1">Applications:</Typography>
        <Grid item maxWidth={'100%'}>
          {!applications.length && (
            <Grid item height={300} width="100%">
              <Typography>
                Once your files have been submitted for analysis, they will
                appear here
              </Typography>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item container padding={1} spacing={1} direction="column">
              {applications.map((uuid) => {
                return (
                  <Grid item container key={uuid} direction="row">
                    <Grid item onClick={() => handleSelect(uuid)}>
                      <Button variant="contained">Application_{uuid}</Button>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </PayloadComparisonColumn>
      <PayloadComparisonColumn>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Processed Results
        </Typography>
        <Grid item>
          <ResultsList container spacing={1} height={320}>
            {!results.length && (
              <Grid item height={300} width="100%" padding={1} flexGrow={0}>
                <ResultsPlaceholder loading={false} />
              </Grid>
            )}
            <Grid
              item
              container
              padding={1}
              spacing={1}
              alignContent="flex-start"
            >
              {results.map((props) => (
                <Grid
                  item
                  xs={6}
                  key={`${props.id}-${props.documentType}-${props.fileType}`}
                >
                  <Result {...props} />
                </Grid>
              ))}
            </Grid>
          </ResultsList>
        </Grid>
      </PayloadComparisonColumn>
    </PayloadComparisonGrid>
  );
}

export default ActiveComparison;
