import React, {useEffect} from 'react';
import SignatureVerificationFileDropInner from './SignatureVerificationFileDropInner';
import {constants, Environment} from '../../constants';

const SignatureVerificationFileDrop: React.FC = () => {
  useEffect(() => {
    if (!window.grecaptcha) {
      // Load reCAPTCHA v3 script
      const recaptchaSiteKey =
        constants.ENVIRONMENT === Environment.Production ||
        constants.ENVIRONMENT === Environment.UAT
          ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PRODUCTION
          : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_STAGING;
      // const recaptcha_site_key =
      //   constants.ENVIRONMENT === Environment.Production ||
      //   constants.ENVIRONMENT === Environment.UAT
      //     ? '6LcD-2omAAAAAPWp3fI2cQlwZ0WNU1slWM7EYlrr'
      //     : '6LeeKG0mAAAAAC5P1X96L7CT9nujcDBdFsNzpfYv';
      console.log(
        `${constants.ENVIRONMENT} key is ${recaptchaSiteKey} in verification`,
      );

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${'6LcD-2omAAAAAPWp3fI2cQlwZ0WNU1slWM7EYlrr'}`;
      // script.src = `https://www.google.com/recaptcha/api.js?render=${'6LcddE8mAAAAADdD05bWqssEff39MIPnkEstLjOD'}`; //for local development
      script.async = true;
      script.onerror = () => {
        console.error('Failed to load reCAPTCHA script.');
      };
      document.body.appendChild(script);
    }
  }, []);

  return <SignatureVerificationFileDropInner />;
};

export default SignatureVerificationFileDrop;
