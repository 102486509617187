import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PageTitle from '@/components/PageTitle';
import ImageBlock from '@/components/ImageBlock';
import whatWeDeliver from '@/assets/who-are-we/financial-insight.webp';
import ourSolutions from '@/assets/who-are-we/our-solutions.webp';
import ourClients from '@/assets/who-are-we/customised-solutions.webp';
import ourPointsOfDifference from '@/assets/who-are-we/api-iframes.webp';
import * as React from 'react';
export default {
  Grid,
  Typography,
  Box,
  PageTitle,
  ImageBlock,
  whatWeDeliver,
  ourSolutions,
  ourClients,
  ourPointsOfDifference,
  React
};